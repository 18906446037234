/* stylelint-disable comment-empty-line-before, comment-whitespace-inside, custom-property-empty-line-before */

body[data-theme='default'] {
  /* palette */
  --red0: #fff7f5;
  --red50: #fff3f0;
  --red100: #fdcfc5;
  --red200: #f9a898;
  --red300: #f28670;
  --red400: #e96a50;
  --red500: #dd5438;
  --red600: #cd4327;
  --red700: #bb2931;
  --red800: #a02a13;
  --red900: #87220e;

  --black80: rgb(30 21 35 / 80%);
  --black60: rgb(30 21 35 / 60%);

  --grey100: #fbfbfd;
  --grey200: #f5f6f9;
  --grey250: #f2efee;
  --grey300: #edeff2;
  --grey400: #e3e5e9;
  --grey700: #b1b3b8;
  --grey900: #828487;

  --base-surface: #fff;
  --base-primary-subdued: #424242;
  --base-critical: #ff4f64;
  --base-success: #cfdfd1;

  --action-primary-default: #f28670;
  --action-primary-hover: #f28670;
  --action-primary-active: #e96a50;
  --action-primary-disabled: var(--red100);

  --text-primary: #2d2d2d;
  --text-secondary: #626262;
  --text-secondary-subdued: #8696a6;
  --text-accent: var(--red300);
  --text-accent-subdued: var(--red300);
  --text-subdued: var(--black80);
  --text-critical: var(--red800);
  --text-success: #13a054;
  --text-disabled: #fff;
  --text-on-primary: #fff;
  --text-on-disabled: #fff;

  --surface-default: #fff;
  --surface-hover: #f2f4f6;
  --surface-active: #fff7f5;
  --surface-critical: #fff7f5;
  --surface-success: #f5fff9;
  --surface-highlight: #fff8fc;
  --surface-secondary-default: #f5f5f5;
  --surface-secondary: #f9f9fa;
  --surface-primary: #f28670;
  --surface-text-highlight: #e96a50;
  --surface-tip: #e2f6e8;
  --surface-tip-warning: #eee9dc;
  --surface-tip-healthy: #e2ecf6;

  --border-primary-default: #f28670;
  --border-primary-subdued: #e1e6ef;
  --border-primary-active: #000;
  --border-secondary-default: #d4dae0;
  --border-secondary: #d9d9d9;
  --border-critical: var(--red800);
  --border-success: #13a043;

  --icon-on-surface: #9e9e9e;
  --icon-on-primary: #fff;
  --icon-subdued: #9e9e9e;
  --icon-critical: var(--red800);
  --experimental-warning: #fffad3ff;

  --debug: #f00;
  --price-active: #424242;
  --price-subdued: #878e95;
}
