/* CSS reset - start */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font: inherit;
  font-size: 100%;
  vertical-align: baseline;

  border: 0;
}

menu,
ol,
ul {
  list-style: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* CSS reset - end */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;

  font-weight: 500;
  text-rendering: optimizelegibility;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: var(--font-family);
}

.suppress-scroll {
  overflow: hidden;
}

.bounce-disabled {
  overscroll-behavior-y: none;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  height: 100%;
}

#onetrust-consent-sdk {
  pointer-events: none;
  opacity: 0;
}

#payment-buttons {
  display: none !important;
}

@media screen and (max-device-width: 480px) {
  body {
    /* stylelint-disable-next-line */
    -webkit-text-size-adjust: 100%;
  }
}

@media (max-width: 768px) {
  /* stylelint-disable-next-line selector-id-pattern */
  .body-in-app-browser #__next {
    height: auto;
    padding-bottom: 125px;
  }
}
